export default function (axios) {
  return {
    // 获取验证码
    sms(params) {
      return axios.get("/pc/sys/sms", { params });
    },
    // 注册
    reg(params) {
      return axios.post("/pc/sys/user/register", params);
    },
    // 登录接口
    login(params) {
      return axios.post("/pc/sys/login", params);
    },
    userInfo(params) {
      return axios.get("/pc/sys/user/getUserInfo", params);
    },
    sealImg(params) {
      return axios.get("/pc/sys/user/sealImg", { params });
    },

    // 登出接口
    logout() {
      return axios.post("/pc/sys/logout");
    },
    // 重设密码
    resetPwd(params) {
      return axios.post("/pc/sys/user/resetPwd", params);
    },

    // 完善资料
    replenish(params) {
      return axios.post("/pc/sys/user/updateUserInfo", params);
    },
    // 上传接口
    upload(params) {
      return axios.post("/pc/sys/common/upload", params);
    },

    queryConfig(params) {
      return axios.get("/pc/sys/config/queryConfig", { params });
    },

    // ---------------------------资金流水------------------------↓
    // 我的资金流水统计接口
    billFlow(params) {
      return axios.get("/pc/sys/bill/billFlow", { params });
    },
    // 我的资金流水列表接口
    billFlowList(params) {
      return axios.post("/pc/sys/bill/billFlowList", params);
    },

    // ---------------------------邀请奖励------------------------↓
    // 生成海报
    createPoster() {
      return axios.get("/pc/sys/user/createPoster");
    },

    // 我的服务经理
    myManager() {
      return axios.get("/pc/sys/user/myManager");
    },
    // 账户信息查询接口
    queryUserInfo(params) {
      return axios.post("/pc/sys/user/queryUserInfo", params);
    },
    // 更换我的服务经理
    changeMyManager(params) {
      return axios.post("/pc/sys/user/changeMyManager", params);
    },
    // 绑定收款账号
    bindAliPayNO(params) {
      return axios.post("/pc/sys/user/bindAliPayNO", params);
    },
    // 团队人员总数接口
    myTeamCount() {
      return axios.get("/pc/sys/team/myTeamCount");
    },
    // 我的团队人员
    myTeam(params) {
      return axios.post("/pc/sys/team/myTeam", params);
    },
    // 我的团队人员
    myTeamRecord(params) {
      return axios.post("/pc/sys/team/myTeamRecord", params);
    },
    // 佣金奖励总数
    myRewardCount() {
      return axios.get("/pc/sys/bill/myRewardCount");
    },
    // 我的团队人员
    myReward(params) {
      return axios.post("/pc/sys/bill/myReward", params);
    },
    // 团队人员详情接口
    teamUserDetail(params) {
      return axios.get("/pc/sys/team/teamUserDetail", { params });
    },
    // 团队成员订单列表接口
    teamOrderList(params) {
      return axios.post("/pc/sys/team/teamOrderList", params);
    },
    // 团队成员订单改价接口
    changePrice(params) {
      return axios.post("/pc/sys/order/changePrice", params);
    },

    // -----------------------------------------项目地址管理
    // 删除项目地址接口
    getCity(params) {
      return axios.get("/pc/sys/city/cityList", { params });
    },
    // 编辑项目地址接口
    addressEdit(params) {
      let url = params.id ? "update" : "add";
      return axios.post(`/pc/sys/address/${url}`, params);
    },
    // 删除项目地址接口
    addressDel(params) {
      return axios.get("/pc/sys/address/delete", { params });
    },
    // 我的项目地址列表接口
    addressList(params) {
      return axios.post("/pc/sys/address/myAddress", params);
    },
    // 设置默认地址接口
    addressDefault(params) {
      return axios.post("/pc/sys/address/setIsDefault", {}, { params });
    },

    // -----------------------------------------系统公告管理
    // 系统公告列表接口
    queryMsg(params) {
      return axios.post("/pc/sys/msg/queryMsg", params);
    },
    // 公告详情接口
    msgDetail(params) {
      return axios.get("/pc/sys/msg/msgDetail", { params });
    },
    // 设置消息已读接口
    updateReadFlag(params) {
      return axios.post("/pc/sys/msg/updateReadFlag", params);
    },
    // 获取使用帮助列表接口
    queryHelpMsg(params) {
      return axios.post("/pc/sys/msg/queryHelpMsg", params);
    },
    // 获取平台资料列表接口
    queryInfoMsg(params) {
      return axios.post("/pc/sys/msg/queryInfoMsg", { params });
    },

    // -----------------------------------------工程单据
    // 工程单据列表接口
    docList(params) {
      return axios.post("/pc/sys/doc/docList", params);
    },
    // 工程单据下载接口
    docDownload(params) {
      return axios.get("/pc/sys/doc/docDownload", { params });
    },

    // -----------------------------------------计算工具
    // 计算工具列表接口
    utilList(params) {
      return axios.post("/pc/sys/util/utilList", params);
    },
    // 工具计算接口
    compute(params) {
      return axios.post("/pc/sys/util/compute", params);
    },

    // -----------------------------------------我的客户
    // 新增/修改客户
    addCustomer(params) {
      let url = params.id ? "updateCustomer" : "addCustomer";
      return axios.post(`/pc/sys/customer/${url}`, params);
    },
    // 删除客户
    deleteCustomer(params) {
      return axios.post("/pc/sys/customer/deleteCustomer", {}, { params });
    },
    // 查询客户列表
    customerList(params) {
      return axios.post("/pc/sys/customer/customerList", params);
    },
    // 查询客户详情
    customerDetail(params) {
      return axios.get("/pc/sys/customer/customerDetail", { params });
    },

    // -----------------------------------------意见反馈
    // 新增意见反馈接口
    addFeedBack(params) {
      return axios.post("/pc/sys/feedBack/addFeedBack", params);
    },

    // -----------------------------------------商品分类
    // 判断用户该商品分类有无访问权限接口
    cateAuth(params) {
      return axios.get("/pc/sys/cate/cateAuth", { params });
    },
    // 商品分类接口
    productType() {
      return axios.get("/pc/sys/cate/cateList");
    },
    // 商品列表接口
    productList(params) {
      return axios.post("/pc/sys/product/productList", params);
    },
    // 热门商品列表接口
    hotProdList(params) {
      return axios.post("/pc/sys/product/hotProdList", params);
    },
    // 商品详情接口
    productDetail(params) {
      return axios.get("/pc/sys/product/productDetail", { params });
    },
    // 商品收藏
    producCollect(params) {
      return axios.post("/pc/sys/product/productCollect", {}, { params });
    },
    // 我的收藏
    myCollect(params) {
      return axios.post("/pc/sys/product/myCollect", params);
    },
    // 获取成品件规格参数接口
    querySkuImg(params) {
      return axios.get("/pc/sys/product/querySkuImg", { params });
    },

    // --------------------------------------------------在线预算
    // 我的预算明细接口--列表查询
    budgetDetail(params) {
      return axios.get("/pc/sys/order/budgetDetail", { params });
    },
    // 添加商品到预算接口--添加商品
    addBudget(params) {
      return axios.post("/pc/sys/order/addBudget", {}, { params });
    },
    // 删除商品类目接口--删除商品
    removeOrdProd(params) {
      return axios.post("/pc/sys/order/removeOrdProd", {}, { params });
    },
    // 预算添加规格接口[预算单]
    addSpecApi(params) {
      return axios.post("/pc/sys/order/add", {}, { params });
    },
    // 预算添加规格排序接口[预算单]
    specSortApi(params) {
      return axios.post("/pc/sys/order/itemSort", params);
    },
    // 规格试算接口
    trial(params) {
      return axios.post("/pc/sys/order/trial", params);
    },
    // 删除规格接口
    removeItem(params) {
      return axios.post("/pc/sys/order/removeItem", {}, { params });
    },
    // 保存单价系数路径接口
    savaMaterialPath(params) {
      return axios.post("/pc/sys/order/savaMaterialPath", params);
    },
    // 保存模型-图纸路径接口
    savaUrl(params) {
      return axios.post("/pc/sys/order/savaUrl", params);
    },
    // 保存其他菜单附属商品价钱接口[预算单]
    saveChild(params) {
      return axios.post("/pc/sys/order/saveAttachProdPrice", {}, { params });
    },

    // 报价单列表接口
    bjdList(params) {
      return axios.post("/pc/sys/order/offerList", params);
    },
    // 报价单详情接口
    offerDetail(params) {
      return axios.get("/pc/sys/order/offerDetail", { params });
    },
    // 修改合同协议接口[合同单]
    updateProtocol(params) {
      return axios.post("/pc/sys/order/updateProtocol", params);
    },
    // 在线预算-保存预算
    saveBudget(params) {
      return axios.post("/pc/sys/order/savaBudget", params);
    },
    // 在线预算-另存为
    saveAsOffer(params) {
      return axios.post("/pc/sys/order/saveAsOffer", params);
    },
    // 在线预算-全部清空,removeBudget清空预算；removeOrder清空订单
    removeBudget(params, type) {
      let api =
        type == "budget"
          ? "/pc/sys/order/removeBudget"
          : "/pc/sys/order/removeOrder";
      return axios.post(api, {}, { params });
    },
    // 删除订单
    delOrder(params) {
      let api = "/pc/sys/order/delete";
      return axios.delete(api, { params });
    },

    // 在线预算-立即下单
    submitOrder(params) {
      return axios.post("/pc/sys/order/submitOrder", {}, { params });
    },
    // 立即下单 → 须知与协议接口[订单]
    agreement(params) {
      return axios.get("/pc/sys/order/agreement", { params });
    },
    // 立即下单 → 申请分期接口[订单]
    applyPeriod(params) {
      return axios.post("/pc/sys/order/applyPeriod", {}, { params });
    },
    // 立即下单 → 同意支付接口[订单]
    agreeToPay(params) {
      return axios.get("/pc/sys/order/agreeToPay", { params });
    },
    // 立即下单 → 支付宝-微信二维码支付，生成二维码
    alipayPrecreate(params) {
      return axios.get("/pc/pay/alipayPrecreate", { params });
    },
    // 立即下单 → 支付宝-微信二维码支付，校验是否支付成功
    payCheck(params) {
      return axios.get("/pc/pay/payCheck", { params });
    },


    // 立即下单 → 支付接口[订单]
    pay(params) {
      return axios.post("/pc/sys/order/pay", params);
    },
    importCustomerProduct(params) {
      return axios.post("/pc/sys/order/importCustomerProduct", params);
    },

    // --------------------------------------------------报价单
    // 更新单件价接口[报价单]
    updatePrice(params) {
      return axios.post("/pc/sys/order/updatePrice", params);
    },
    // 还原单件价接口[报价单]
    resetPrice(params) {
      return axios.post("/pc/sys/order/resetPrice", {}, { params });
    },
    // 报价单转发接口[预算单、报价单、合同单]
    orderForward(params) {
      return axios.get("/pc/sys/order/orderForward", { params });
    },
    // 出货单转发接口[出货单、出货单汇总]
    orderOutForward(params) {
      return axios.get("/pc/sys/order/orderOutForward", { params });
    },
    // 报价单保存接口[报价单]
    savaOffer(params) {
      return axios.post("/pc/sys/order/savaOffer", params);
    },

    // 生成合同单
    createContract(params) {
      return axios.post("/pc/sys/order/createContract", {}, { params });
    },

    // --------------------------------------------------合同单
    // 合同单列表接口
    htdList(params) {
      return axios.post("/pc/sys/order/contractList", params);
    },
    // 合同单明细接口[合同单]
    htdInfo(params) {
      return axios.get("/pc/sys/order/contractDetail", { params });
    },
    // 删除合同[合同单]
    removeContract(params) {
      return axios.post("/pc/sys/order/removeContract", {}, { params });
    },
    // --------------------------------------------------出货单
    // New出货单列表
    myOutOrderList(params) {
      return axios.post("/pc/sys/order/myOutOrderList", params);
    },
    // 出货单列表接口[出货单]
    orderOutList(params) {
      return axios.post("/pc/sys/order/orderOutList", params);
    },
    // 出货单明细接口[出货单]
    orderOutDetail(params) {
      return axios.get("/pc/sys/order/orderOutDetail", { params });
    },
    // 编辑运输信息接口[出货单]
    updateExpress(params) {
      return axios.post("/pc/sys/order/updateExpress", {}, { params });
    },
    // 出货汇总接口[出货单]
    orderOutAllDetail(params) {
      return axios.get("/pc/sys/order/orderOutAllDetail", { params });
    },

    // --------------------------------------------------订单
    // 查询年月订单统计
    orderStatics(params) {
      return axios.post("/pc/sys/order/orderStatics", params);
    },
    // 订单列表接口[订单]
    orderList(params) {
      return axios.post("/pc/sys/order/orderList", params);
    },
    // 待下单列表接口
    orderWaitList(params) {
      return axios.post("/pc/sys/order/orderWaitList", params);
    },
    // 添加到订单接口
    addToOrder(params) {
      return axios.post("/pc/sys/order/addToOrder", {}, { params });
    },

    // 添加到订单接口
    bannerList(params) {
      return axios.get("/pc/sys/doc/bannerList", { params });
    },

    // 微信分享
    wxTicket() {
      return axios.get("/pc/sys/wxTicket");
    },
  };
}

// 以下是未绑接口，暂时未知作用的接口
// 账单日期列表接口：/pc/sys/bill/dateList
// 获取客服配置信息接口：/pc/sys/config/csConfig（感觉与，获取系统配置接口：/pc/sys/config/queryConfig接口重复）
